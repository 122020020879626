'use client'

import Loader from '@/components/Loader';
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { ImSpinner8 } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';

function LoginOTPAuthenticateAccountClient() {

  const [otp, setOtp] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const { authCred, setUser, setIsAuthenticated } = useApp();
  const navigate = useNavigate();
  const toast = useToast();

  if (authCred === null) {
    navigate('/auth/login');
  }

  async function finalizeSignin() {
    setIsFetching(true);
    try {
      const resp = await http("account/login/finalise", { reference_code: authCred!.reference_code, otp }, process.env.REACT_APP_PUBLIC_KEY);
      setUser({
        business_team_id: resp.data.business_team_id,
        account_connect_id: resp.data.account_connect_id,
        firstname: resp.data.firstname,
        lastname: resp.data.lastname,
        email: resp.data.email,
        phone: resp.data.phone,
        photo: resp.data.photo,
        status: resp.data.status,
        active: resp.data.active,
        role: resp.data.role,
        created_on: resp.data.created_on
      })
      localStorage.setItem('app-tkn', resp.data.token);
      setIsAuthenticated(true);
      console.log(resp)
      navigate('/d/overview');
    } catch (error) {
      console.log(error);  
      setIsFetching(false);        
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      {authCred === null ?
        <Loader /> :
        <div className='flex justify-center h-full'>
          <div className='mt-[150px]'>
            <div className='w-[300px] md:w-[400px] lg:w-[450px]px-6 py-5'>
              <div className='font-bold mb-10 text-lg border-b-[5px] border-b-purple-800 inline-block'>Enter OTP</div>

              <div className='mb-1 text-xs'>
                <div className=''>An OTP was sent to {authCred.email}</div>
              </div>
              <div className='mb-6 text-xs'>
                <div>Enter the OTP below to complete sign in</div>
              </div>

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-600 dark:text-neutral-500 mb-[5px]'>OTP</div>
                <input type="text" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setOtp(e.target.value)} placeholder='Enter OTP' disabled={isFetching} />
              </div>

              <div className='mb-3'>
                <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 w-full disabled:bg-opacity-50 flex justify-center' onClick={() => finalizeSignin()} disabled={isFetching}>
                  {
                    isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                  }
                </button>
              </div>
              <div className='text-xs flex gap-3'>
                <div>User another account ?</div>
                <Link to={'/auth/login'} className='text-purple-800 font-bold'>Sign in</Link>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default LoginOTPAuthenticateAccountClient