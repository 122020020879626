'use client'

import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { formatDateNoTimezone } from '@/utils/helpers';

function AddSubscriptionPaymentModal({
  children,
  business_id,
  setPayments
}: {
  children: React.ReactNode;
  business_id: string,
  setPayments: React.Dispatch<React.SetStateAction<any[]>>
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [subId, setSubId] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [referenceCode, setReferenceCode] = useState<string>("");
  const [paymentTimestamp, setPaymentTimestamp] = useState<string>("");
  const [addedTimestamp, setAddedTimestamp] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);

  async function getOptions() {
    try {
      const resp = await http("business/subscription/options", { business_id });
      console.log("options", resp.data)
      setOptions(resp.data)
      setIsFetchingUsers(false);
    } catch (error) {
      console.log(error)
      setIsFetchingUsers(false);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function addSubscription() {
    setIsFetching(true);
    try {
      const resp = await http("business/subscription/add", {
        business_id,
        subscription_id: subId,
        details,
        duration,
        added_timestamp: addedTimestamp,
        payment_timestamp: paymentTimestamp,
        reference_code: referenceCode,
        amount
      });
      console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      window.location.reload();
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Add subscription</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <div className='mb-3'>
              {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div> */}
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-3'>
                  <div>Select Subscription</div>
                  {isFetchingUsers && <div><ImSpinner8 /></div>}
                </div>
                <select value={subId} onChange={(e) => setSubId(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} >
                  <option value="">Select</option>
                  {
                    options.map((u, i) => (
                      <option value={u.subscription_id} key={i}>{u.subscription}</option>
                    ))
                  }
                </select>
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Details</div>
                <input type='text' value={details} onChange={(e) => setDetails(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Amount</div>
                <input type='text' value={amount} onChange={(e) => setAmount(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Duration</div>
                <input type='text' value={duration} onChange={(e) => setDuration(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Invoice generation date</div>
                <input type='datetime-local' value={addedTimestamp} onChange={(e) => setAddedTimestamp(formatDateNoTimezone(new Date(e.target.value)))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Payment date</div>
                <input type='datetime-local' value={paymentTimestamp} onChange={(e) => setPaymentTimestamp(formatDateNoTimezone(new Date(e.target.value)))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Reference code (optional)</div>
                <input type='text' value={referenceCode} onChange={(e) => setReferenceCode(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>

              <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => addSubscription()} disabled={isFetching}>
                {
                  isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                }
              </button>
            </div>
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddSubscriptionPaymentModal