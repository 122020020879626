'use client'

import http from '@/lib/http';
import { Skeleton } from '@chakra-ui/react';
// import Image from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { FcBusinessman } from "react-icons/fc";
import { FiUserPlus } from "react-icons/fi";
import UpdateTeamModal from '../modals/UpdateTeamModal';
import AddTeamModal from '../modals/AddTeamModal';
import AutoGenTableViewModal from '../modals/AutoGenTableViewModal';
import FilterForm from '../FilterForm';

function TeamClient() {

  const [isFetching, setIsFetching] = useState(true);
  const [teams, setTeams] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [paginationInput, setPaginationInput] = useState<any>();
    const filterInputs = {
        email: "",
        role: "",
        status: "",
        order_by: ""
    }
    function setPaginate(resp: any) {
        setPaginationInput({
            counts: resp.counts,
            totalPages: resp.totalPages,
            currentPage: resp.currentPage,
            listPerPage: resp.listPerPage
        });
    }

  async function fetchTeams() {
    try {
      const resp = await http("team/details", {limit: 10});
      console.log("resp", resp.data)
      setTeams(resp.data)
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  const filtered = useMemo(() => {
    if (teams.length === 0) return [];
    //  if(search === "");
    const sorted_ = sort === "date-asc" ?
      teams.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
      sort === "date-desc" ?
        teams.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
        sort === "z-a" ?
          teams.slice().sort((a: any, b: any) => b.firstname.localeCompare(a.firstname)) :
          teams.slice().sort((a: any, b: any) => a.firstname.localeCompare(b.firstname));

    const filtered_ = search.length > 0 ?
      sorted_.filter((e: any) => `${e.firstname} ${e.lastname}`.toLowerCase().includes(search.toLowerCase())) :
      sorted_;

    return filtered_;

  }, [search, sort, teams]);


  function updateteam(email: string, role: string, status: string) {
    const index = teams.findIndex(team => team.email === email);
    if (index !== -1) {
      const updatedTeams = [...teams];
      updatedTeams[index].role = role;
      updatedTeams[index].status = status;
      setTeams(updatedTeams);
    }
  };

  return (
    <>
      <div className='sm:px-10 px-5 py-8 w-full'>

        <div className='mb-10 text-xl'>Lucrestack Team</div>

        <div className='mb-8 flex gap-5 items-center'>
          <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
          <div className='flex-1'>
            <div className='text-xs'>Sort by</div>
            <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
              <option value="date-asc">Date (Ascending)</option>
              <option value="date-desc">Date (Descending)</option>
            </select>
          </div>
        </div>

        <hr className='mb-6 border-slate-300 dark:border-neutral-700' />

        <FilterForm
                url="team/details"
                inputs={filterInputs}
                paginationInput={paginationInput}
                isLoading={isFetching}
                setIsLoading={setIsFetching}
                setState={setTeams}
                setPaginationInput={setPaginationInput}
                inputtedIsSelectTag={{ status: ["Request", "Approved", "Banned"], role: ["Admin", "Agent", "Officer"] }}
            />

        <div className='flex w-full justify-between mb-6 items-center'>
          <div></div>
          <AddTeamModal>
            <div className='flex gap-3 text-purple-500'>
              <FiUserPlus />
              <div className='text-xs'>Add team member</div>
            </div>
          </AddTeamModal>
        </div>

        <div className='mb-10 overflow-auto max-h-[500px]'>
          <div className='grid grid-cols-1 md:grid-cols-3 sm:gap-6 gap-3'>

            {!isFetching ?
              filtered.map((t, i) => (
                <div key={i} className='flex gap-3 items-center mb-5 border-2 border-neutral-300 dark:border-neutral-700 p-5'>
                  {/* <div className='w-[50px] h-[40px] overflow-hidden rounded-full hidden md:inline-block'>
                                <Image src={'/dummy-profile/image1.avif'} className='w-[45px] rounded-full' alt='photo' width={50} height={50} />
                              </div> */}
                  <div className='px-2 py-2 rounded-full flex justify-center'>
                    <FcBusinessman className='w-7 h-7' />
                  </div>
                  <div className='flex justify-between w-full items-center'>
                    <div className='text-xs'>
                      <div className='font-bold'>{`${t.firstname} ${t.lastname}`}</div>
                      <div className=''>{t.email}</div>
                      <div>status: {t.status}</div>
                      <div>Role: {t.role}</div>
                      <div className='mb-5'>joined: {t.created_on}</div>
                      <div className='cursor-pointer'>
                        <div className='flex items-center gap-3 text-[10px]'>
                          <AutoGenTableViewModal title='Team member' data={t}>
                            <div className='flex gap-1 items-center font-bold'>
                              <FaLongArrowAltRight className='w-2 h-2' />
                              <div>View full info</div>
                            </div>
                          </AutoGenTableViewModal>

                          <UpdateTeamModal data={t} updateTeam={updateteam}>
                            <div className='flex gap-1 items-center font-bold'>
                              <FaLongArrowAltRight className='w-2 h-2' />
                              <div>Update</div>
                            </div>
                          </UpdateTeamModal>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              )) :
              Array.from(Array(8)).map((k) => (
                <Skeleton h={'130px'} key={k} />
              ))
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default TeamClient