"use client";

import FilterForm from "@/components/FilterForm";
import AutoGenTableViewModal from "@/components/modals/AutoGenTableViewModal";
import http from "@/lib/http";
import { Skeleton } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { CgToolbox } from "react-icons/cg";
import { FaAngleRight } from "react-icons/fa6";
import { PiCurrencyNgnDuotone } from "react-icons/pi";
import { useLocation } from "react-router-dom";
import { IoMdBusiness } from "react-icons/io";
import { SiGooglesearchconsole } from "react-icons/si";
import { MdSupervisedUserCircle } from "react-icons/md";
import { FaUsersViewfinder } from "react-icons/fa6";
import { GiProcessor } from "react-icons/gi";

function BusinessServicesTransactionsClient() {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const { pathname } = useLocation();

  const [earnings, setEarnings] = useState<{
    business: number;
    console: number;
    user_referrer: number;
    partner: number;
    processor: number;
  } | null>(null);
  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    currency: "NGN",
    service_id: "",
    business_partner_id: "",
    business_console_id: "",
    account: "",
    account_id: "",
    request_type: "",
    date_filter_from: "",
    date_filter_to: "",
    order_by: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchServices() {
    try {
      const resp = await http("business/services/transactions", {
        business_id: getBusinessId(),
        limit: 10,
      });
      console.log("resp", resp);
      setTransactions(resp.data);
      setEarnings(resp.earning);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredTransactions = useMemo(() => {
    if (transactions.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? transactions
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? transactions
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? transactions
            .slice()
            .sort((a: any, b: any) => b.service.localeCompare(a.service))
        : transactions
            .slice()
            .sort((a: any, b: any) => a.service.localeCompare(b.service));

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            e.service.toLowerCase().includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, transactions]);

  function getBusinessId() {
    const split_path = pathname.split("/");
    return split_path[3];
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div>Businesses</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Services</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Transactions</div>
        </div>
      </div>

      <div className="mb-10 text-xl">Services Transactions</div>

      <div className="flex items-center gap-5 mb-8">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-10 border-slate-300 dark:border-neutral-700" />

      <FilterForm
        url="business/services/transactions"
        payload={{ business_id: getBusinessId() }}
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setTransactions}
        setPaginationInput={setPaginationInput}
        inputtedIsSelectTag={{
          request_type: ["Credit", "Debit"],
          currency: ["NGN", "USD"],
        }}
      />

      <div className="pt-2 pb-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
            <CgToolbox className="w-6 h-6" />
            <div>Earnings</div>
          </div>
          {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <IoMdBusiness className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Business</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && earnings ? (
                      <span>{earnings?.business?.toFixed(2)}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <SiGooglesearchconsole className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Console</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && earnings ? (
                      <span>{earnings?.console?.toFixed(2)}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <MdSupervisedUserCircle className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">User Referrer</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && earnings ? (
                      <span>{earnings?.user_referrer?.toFixed(2)}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <FaUsersViewfinder className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Partner</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && earnings ? (
                      <span>{earnings?.partner?.toFixed(2)}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <GiProcessor className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Processor</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && earnings ? (
                      <span>{earnings?.processor?.toFixed(2)}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="mb-10 border-slate-300 dark:border-neutral-700" />

      <div className="w-full mb-10">
        <div className="relative overflow-x-auto">
          <table className="w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Service name
                </th>
                <th scope="col" className="px-6 py-3">
                  Currency type
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Amount
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                  Provider Amount
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                  Partner Amount
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching
                ? filteredTransactions.map((d, k) => (
                    <tr
                      key={k}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {d.service}
                      </th>
                      <td className="px-6 py-4">{d.currency_type}</td>
                      {/* <td className="px-6 py-4">{d.amount}</td>
                      <td className="px-6 py-4">{d.provider_amount}</td>
                      <td className="px-6 py-4">{d.partner_amount}</td> */}
                      <td className="px-6 py-4">{d.status}</td>
                      <td className="flex gap-3 px-6 py-4">
                        <AutoGenTableViewModal title="Transaction" data={d}>
                          <span className="font-bold text-blue-600">View</span>
                        </AutoGenTableViewModal>
                      </td>
                    </tr>
                  ))
                : Array.from(Array(5)).map((k, i) => (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <Skeleton h={"20px"} />
                      </td>
                      {/* <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td> */}
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BusinessServicesTransactionsClient;
