import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthLayoutClient from './components/AuthLayoutClient';
import LoginClient from './components/pages/auth/LoginClient';
import LoginOTPAuthenticateAccountClient from './components/pages/auth/LoginOTPAuthenticateAccountClient';
import DashboardLayout from './components/DashboardLayout';
import AccountDetailsClient from './components/pages/account/AccountDetailsClient';
import KycClient from './components/pages/KycClient';
import NotificationClient from './components/pages/NotificationClient';
import ServicesClient from './components/pages/ServicesClient';
import OverviewClient from './components/pages/OverviewClient';
import TeamClient from './components/pages/TeamClient';
import BusinessLayoutClient from './components/BusinessLayoutClient';
import SelectBusinessClient from './components/pages/businesses/SelectBusinessClient';
import BusinessCreateClient from './components/pages/businesses/BusinessCreateClient';
import BusinessOverviewClient from './components/pages/businesses/BusinessOverviewClient';
import BusinessAccountDetailsClient from './components/pages/businesses/BusinessAccountDetailsClient';
import BusinessAccountDocumentClient from './components/pages/businesses/BusinessAccountDocumentClient';
import BusinessCommissionDetailsClient from './components/pages/businesses/BusinessCommissionDetailsClient';
import BusinessCommissionPaymentsClient from './components/pages/businesses/BusinessCommissionPaymentsClient';
import BusinessServicesDetailsClient from './components/pages/businesses/BusinessServicesDetailsClient';
import BusinessServicesTransactionsClient from './components/pages/businesses/BusinessServicesTransactionsClient';
import BusinessSubscriptionPaymentsClient from './components/pages/businesses/BusinessSubscriptionPaymentsClient';
import BusinessTeamClient from './components/pages/businesses/BusinessTeamClient';
import BusinessUsersClient from './components/pages/businesses/BusinessUsersClient';
import BusinessWalletClient from './components/pages/businesses/BusinessWalletClient';
import BusinessWalletTransactionsClient from './components/pages/businesses/BusinessWalletTransactionsClient';
import RetrieveAccountCient from './components/pages/auth/RetrieveAccountClient';

function App() {
  return (
    <Routes>
      <Route path='' element={<Navigate to={'/d/overview'} />} ></Route>
      <Route element={<AuthLayoutClient />}>
        <Route path='/auth/login' element={<LoginClient />} ></Route>
        <Route path='/auth/login/authenticate' element={<LoginOTPAuthenticateAccountClient />} ></Route>
        <Route path='/auth/retrieve-account' element={<RetrieveAccountCient />} ></Route>
      </Route>
      <Route path='d' element={<DashboardLayout />}>
        <Route path='account' element={<AccountDetailsClient />} ></Route>
        <Route path='kyc' element={<KycClient />} ></Route>
        <Route path='notification' element={<NotificationClient />} ></Route>
        <Route path='overview' element={<OverviewClient />} ></Route>
        <Route path='services' element={<ServicesClient />} ></Route>
        <Route path='team' element={<TeamClient />} ></Route>
        <Route path='businesses' >
          <Route path='' element={<SelectBusinessClient />} ></Route>
          <Route path='create' element={<BusinessCreateClient />} ></Route>
          <Route path=':id' element={<BusinessLayoutClient />} >
            <Route path='overview' element={<BusinessOverviewClient />} ></Route>
            <Route path='account/details' element={<BusinessAccountDetailsClient />} ></Route>
            <Route path='account/documents' element={<BusinessAccountDocumentClient />} ></Route>
            <Route path='commission/details' element={<BusinessCommissionDetailsClient />} ></Route>
            <Route path='commission/payments' element={<BusinessCommissionPaymentsClient />} ></Route>
            <Route path='services/details' element={<BusinessServicesDetailsClient />} ></Route>
            <Route path='services/transactions' element={<BusinessServicesTransactionsClient />} ></Route>
            <Route path='subscription/payments' element={<BusinessSubscriptionPaymentsClient />} ></Route>
            <Route path='team' element={<BusinessTeamClient />} ></Route>
            <Route path='users' element={<BusinessUsersClient />} ></Route>
            <Route path='wallet/details' element={<BusinessWalletClient />} ></Route>
            <Route path='wallet/transactions' element={<BusinessWalletTransactionsClient />} ></Route>
          </Route>

        </Route>
      </Route>
    </Routes>
  );
}

export default App;