"use client";

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import http from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { useTheme } from "@/contexts/themeProvider";

function AddCommissionModal({
  children,
  business_id,
  setDetails,
}: {
  children: React.ReactNode;
  business_id: string;
  setDetails: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [rate, setRate] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [rateCapped, setRateCapped] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);

  async function getUsers() {
    try {
      const resp = await http("business/user/details", { business_id });
      console.log("users", resp.data);
      setUsers(resp.data);
      setIsFetchingUsers(false);
    } catch (error) {
      console.log(error);
      setIsFetchingUsers(false);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function addCommission() {
    setIsFetching(true);
    try {
      const resp = await http("business/commission/add", {
        business_id,
        user_id: userId,
        rate_capped: rateCapped,
        rate,
      });
      console.log("resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      window.location.reload();
      // setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Add commission
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="mb-3">
              {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div> */}
              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-3">
                  <div>User</div>
                  {/* { isFetchingUsers && <div><ImSpinner8 /></div>} */}
                </div>
                <input
                  type="text"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
                {/* <select value={userId} onChange={(e) => setUserId(e.target.value)} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' disabled={isFetching} >
                  <option value="">Select user</option>
                  {
                    users.map((u, i) => (
                      <option value={u.details.user_id} key={i}>{u.details.firstname} {u.details.lastname} - {u.details.email}</option>
                    ))
                  }
                </select> */}
              </div>
              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Rate
                </div>
                <input
                  type="text"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>
              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Rate Capped
                </div>
                <input
                  type="text"
                  value={rateCapped}
                  onChange={(e) => setRateCapped(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                onClick={() => addCommission()}
                disabled={isFetching}
              >
                {isFetching ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddCommissionModal;
