"use client";

import DebitWalletModal from "@/components/modals/DebitWalletModal";
import TopupWalletModal from "@/components/modals/TopupWalletModal";
import UpdateWalletModal from "@/components/modals/UpdateWalletModal";
import AutoGenNormalViewModal from "@/components/modals/AutoGenNormalViewModal";
import WithdrawEarningsWalletModal from "@/components/modals/WithdrawEarningsWalletModal";
import http from "@/lib/http";
import { currencyFormat } from "@/utils/helpers";
import { Skeleton } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { TbCurrencyNaira } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import FilterForm from "@/components/FilterForm";
import { BiDollar } from "react-icons/bi";
// import { PiDotsThreeOutlineBold, PiUsersThreeBold } from 'react-icons/pi'

function BusinessWalletClient() {
  const [isFetching, setIsFetching] = useState(true);
  const [wallets, setWallets] = useState<any[]>([]);
  const { pathname } = useLocation();
  const [show, setShow] = useState("");

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    currency: "NGN",
    account: "",
    account_id: "",
    date_filter_from: "",
    date_filter_to: "",
    order_by: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchWallets() {
    try {
      const resp = await http("business/wallet/details", {
        business_id: getBusinessId(),
        limit: 10,
      });
      // console.log("resp", resp.data)
      setWallets(resp.data);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getBusinessId() {
    const split_path = pathname.split("/");
    return split_path[3];
  }

  const filtered = useMemo(() => {
    if (wallets.length === 0) return [];

    const filtered_ =
      show.length > 0
        ? wallets.filter((e: any) =>
            `${e.type}`.toLowerCase().includes(show.toLowerCase())
          )
        : wallets;

    return filtered_;
  }, [wallets, show]);

  function walletTopup(
    business_id: string,
    wallet_id: string,
    remark: string,
    amount: string
  ) {
    const index = wallets.findIndex((wallet) => wallet.wallet_id === wallet_id);
    if (index !== -1) {
      const updatedWallet = [...wallets];
      updatedWallet[index].available_balance = `${
        parseFloat(updatedWallet[index].available_balance) + parseFloat(amount)
      }`;
      setWallets(updatedWallet);
    }
  }

  function walletDebit(
    business_id: string,
    wallet_id: string,
    remark: string,
    amount: string
  ) {
    const index = wallets.findIndex((wallet) => wallet.wallet_id === wallet_id);
    if (index !== -1) {
      const updatedWallet = [...wallets];
      updatedWallet[index].available_balance = `${
        parseFloat(updatedWallet[index].available_balance) - parseFloat(amount)
      }`;
      setWallets(updatedWallet);
    }
  }

  function walletUpdate(
    business_id: string,
    wallet_id: string,
    active: string,
    locked_balance: string
  ) {
    const index = wallets.findIndex((wallet) => wallet.wallet_id === wallet_id);
    if (index !== -1) {
      const updatedWallet = [...wallets];
      updatedWallet[index].locked_balance = locked_balance;
      setWallets(updatedWallet);
    }
  }

  return (
    <>
      <div className="flex items-center gap-3 mb-10 text-sm text-slate-500">
        <div>Businesses</div>
        <div>
          <FaAngleRight />
        </div>
        <div>wallet</div>
      </div>

      <div className="mb-5 text-xl">Wallet</div>

      <div className="flex items-center gap-5 mb-8">
        {/* <input type="text" className='px-3 py-3 sm:w-[400px] flex-1 sm:flex-none text-sm border-2 border-neutral-600 dark:bg-neutral-700' placeholder='Enter Search Keyword' /> */}
        <div>
          <div className="text-xs">Show</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={show}
            onChange={(e) => setShow(e.target.value)}
          >
            <option value="">All</option>
            <option value="credit">Credit</option>
            <option value="general">General</option>
          </select>
        </div>
      </div>

      <FilterForm
        url="business/wallet/details"
        payload={{ business_id: getBusinessId() }}
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setWallets}
        setPaginationInput={setPaginationInput}
        inputtedIsSelectTag={{
          account: ["User", "Business"],
          currency: ["NGN", "USD"],
        }}
      />

      <div className="mb-10">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 sm:gap-6">
          {!isFetching
            ? filtered.map((w, i) => (
                <div
                  key={i}
                  className="px-3 py-4 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800"
                >
                  <div className="flex items-start gap-3">
                    <div className="p-1 bg-green-700 text-neutral-100 sm:p-3">
                      {w.currency === "ngn" ? (
                        <TbCurrencyNaira className="w-7 h-7" />
                      ) : (
                        <BiDollar className="w-7 h-7" />
                      )}
                    </div>
                    <div className="w-full">
                      <div className="mb-3">
                        <div className="flex gap-1 text-xs">
                          <div>Type:</div>
                          <div className="text-orange-600 underline">
                            {w.type}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="text-[10px]">Available balance</div>
                        <div>{currencyFormat(w.available_balance)}</div>
                      </div>
                      <div className="mb-3">
                        <div className="text-[10px]">Pending balance</div>
                        <div>{currencyFormat(w.pending_balance)}</div>
                      </div>
                      <div className="mb-3">
                        <div className="text-[10px]">Locked balance</div>
                        <div>{currencyFormat(w.locked_balance)}</div>
                      </div>
                      <div className="flex gap-5 mb-8">
                        <div>
                          <div className="text-[10px]">Total inflow</div>
                          <div>{w.inflow.total}</div>
                        </div>
                        <div>
                          <div className="text-[10px]">Total outflow</div>
                          <div>{w.outflow.total}</div>
                        </div>
                      </div>
                      <div className="">
                        <div className="text-xl text-opacity-80 "></div>
                        <div className="flex gap-2 mb-3 font-bold">
                          <TopupWalletModal
                            data={w}
                            walletTopup={walletTopup}
                            business_id={getBusinessId()}
                          >
                            <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                              <FaLongArrowAltRight className="w-2 h-2" />
                              <div>Topup</div>
                            </div>
                          </TopupWalletModal>
                          <DebitWalletModal
                            data={w}
                            walletDebit={walletDebit}
                            business_id={getBusinessId()}
                          >
                            <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                              <FaLongArrowAltRight className="w-2 h-2" />
                              <div>Debit</div>
                            </div>
                          </DebitWalletModal>
                          <UpdateWalletModal
                            data={w}
                            walletUpdate={walletUpdate}
                            business_id={getBusinessId()}
                          >
                            <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                              <FaLongArrowAltRight className="w-2 h-2" />
                              <div>Update</div>
                            </div>
                          </UpdateWalletModal>
                          {/* <TopupWalletModal data={w} walletTopup={walletTopup} business_id={getBusinessId()}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>Withdraw earnings</div>
                                                    </div>
                                                </TopupWalletModal> */}
                        </div>
                        <div className="flex gap-2 font-bold">
                          <AutoGenNormalViewModal
                            exclude={[""]}
                            title="Virtual accounts"
                            data={w.virtual_funding_account}
                          >
                            <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                              <FaLongArrowAltRight className="w-2 h-2" />
                              <div>View virtual accounts</div>
                            </div>
                          </AutoGenNormalViewModal>

                          {/* <WithdrawEarningsWalletModal
                            business_id={getBusinessId()}
                            wallet_id={w.wallet_id}
                          >
                            <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                              <FaLongArrowAltRight className="w-2 h-2" />
                              <div>Withdraw earnings</div>
                            </div>
                          </WithdrawEarningsWalletModal> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : Array.from(Array(6)).map((k) => <Skeleton h={"130px"} key={k} />)}
        </div>
      </div>
    </>
  );
}

export default BusinessWalletClient;
