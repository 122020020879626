'use client'

import React, { useEffect } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
import { FaAngleDown } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@/contexts/themeProvider';

function BusinessMobileDrawer({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {pathname} = useLocation();
    const {resolvedTheme} = useTheme();

    useEffect(() => {
        onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            <div onClick={onOpen} className='w-full px-5 pb-2 pt-3 bg-slate-200 dark:bg-neutral-700 flex md:hidden items-center gap-2 shadow-lg cursor-pointer'>
                <div className='text-xs font-bold'>Menu</div><FaAngleDown />
            </div>

            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent bg={resolvedTheme === "dark" ? '#262626' : '#fff'} color={resolvedTheme === "dark" ? '#fff' : '#000'}>
                    <DrawerCloseButton />
                    <DrawerHeader><div className='text-[14px]'>Menu</div></DrawerHeader>

                    <DrawerBody>
                        {children}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default BusinessMobileDrawer