import AppLayoutClient from '@/components/AppLayoutClient';
import PanelHeader from '@/components/PanelHeader';
import React from 'react'
import { Outlet } from 'react-router-dom';

function DashboardLayout() {
    return (
        <>
            <AppLayoutClient>
                <PanelHeader />
                <div className='bg-white dark:bg-neutral-900 min-h-lvh dark:text-neutral-200 font-container'>
                    <Outlet />
                </div>
            </AppLayoutClient>
        </>
    )
}

export default DashboardLayout