'use client'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { useApp } from '@/contexts/appContext';

function AppLayoutClient({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {

    const navigate = useNavigate();
    const { isAuthenticating, isAuthenticated } = useApp();

    useEffect(() => {
        if (!isAuthenticating && !isAuthenticated) {
            localStorage.removeItem('app-tkn');
            navigate('/auth/login', { replace: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticating, isAuthenticated]);

    return (
        <div className='font-container'>
            {!isAuthenticating && isAuthenticated ?
                <>
                    {children}
                </> :
                <Loader />
            }
        </div>
    )
}

export default AppLayoutClient