"use client";

import React, { useEffect, useState } from "react";
import {
  FcViewDetails,
  FcSynchronize,
  FcCheckmark,
  FcCancel,
  FcBusinessman,
} from "react-icons/fc";
import { PiCurrencyNgnDuotone, PiDotsThreeOutlineBold } from "react-icons/pi";
import { MdAddBusiness } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FcFlashAuto, FcFlashOff } from "react-icons/fc";
import { PiUsersThreeBold } from "react-icons/pi";
import { FcComboChart } from "react-icons/fc";
import { FcAdvertising } from "react-icons/fc";
import http from "@/lib/http";
import { ITeam, OverviewMetric } from "@/types/global.types";
import { Skeleton } from "@chakra-ui/react";
import UpdateTeamModal from "../modals/UpdateTeamModal";
import { IBusiness } from "@/types/user.type";
import { Link, useNavigate } from "react-router-dom";
import { IoLogoUsd } from "react-icons/io5";
import { CgToolbox } from "react-icons/cg";

function OverviewClient() {
  const [isFetching, setIsFetching] = useState(true);
  const [metric, setMetric] = useState<OverviewMetric | null>(null);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [businessHighestUser, setBusinessHighestUser] =
    useState<IBusiness | null>(null);
  const [businessInflow, setBusinessInflow] = useState<IBusiness | null>(null);
  const [businessOutflow, setBusinessOutflow] = useState<IBusiness | null>(
    null
  );

  const navigate = useNavigate();

  async function getMetric() {
    try {
      const resp = await Promise.all([
        http("metrics/details"),
        http("team/details", { limit: 5 }),
      ]);
      console.log("metric", resp[0].data);
      console.log("team", resp[1].data);
      setMetric(resp[0].data);
      setTeams(resp[1].data);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMetric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = async (business_id: string) => {
    if (metric !== null) {
      const resp = await http("business/account/details", {
        business_id: business_id,
      });
      if (resp.data.length > 0) {
        return resp.data[0] as IBusiness;
      }
      return null;
    }
    return null;
  };

  useEffect(() => {
    async function fetchBusiness() {
      if (metric !== null) {
        const fetchBusinessHighestUser = await fetch(
          metric?.highest?.business_with_user
        );
        const fetchBusinessInflow = await fetch(
          metric?.highest?.business_with_inflow
        );
        const fetchBusinessOutflow = await fetch(
          metric?.highest?.business_with_outflow
        );
        setBusinessHighestUser(fetchBusinessHighestUser);
        setBusinessInflow(fetchBusinessInflow);
        setBusinessOutflow(fetchBusinessOutflow);
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric]);

  function updateteam(email: string, role: string, status: string) {
    const index = teams.findIndex((team) => team.email === email);
    if (index !== -1) {
      const updatedTeams = [...teams];
      updatedTeams[index].role = role;
      updatedTeams[index].status = status;
      setTeams(updatedTeams);
    }
  }

  return (
    <div className="grid grid-cols-1 gap-10 sm:flex ">
      <div className="px-5 py-8 sm:px-10 sm:w-2/3">
        <div className="mb-10">
          <div className="mb-4 text-2xl font-bold">Overview</div>
          <div className="text-xs">
            Welcome to the Lucrestack Super Admin Dashboard! Here, you have the
            power to oversee and manage multiple businesses utilizing our
            platform. With comprehensive analytics and monitoring tools at your
            fingertips.
          </div>
        </div>
        <div className="mb-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
              <MdAddBusiness className="w-6 h-6" />
              <div>Businesses</div>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => navigate("/d/businesses")}
            >
              <div className="text-xs font-bold underline">Manage</div>
              <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
                <PiDotsThreeOutlineBold className="w-4 h-4" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 sm:gap-6">
            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Total
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px]">
                    Total businesses registered or created on Lucrestack
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.business.total}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Pending
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <FcSynchronize className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px] pt-1">
                    Businesses awaiting approval on Lucrestack
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.business.pending}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Approved
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-green-100 sm:p-3">
                  <FcCheckmark className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px] pt-1">
                    Total Approved businesses on Lucrestack
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.business.approved}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Banned
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-red-100 sm:p-3">
                  <FcCancel className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px]">
                    Businesses banned from operating on Lucrestack
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.business.banned}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Active subscription
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <FcFlashAuto className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px]">
                    Businesses with active subscription on Lucrestack
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.business.active_subscription}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Inactive subscription
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-red-100 sm:p-3">
                  <FcFlashOff className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px]">
                    Businesses with inactive subscription on Lucrestack
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.business.inactive_subscription}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
              <PiUsersThreeBold className="w-6 h-6" />
              <div>Users</div>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => navigate("/d/businesses")}
            >
              <div className="text-xs font-bold underline">View</div>
              <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
                <PiDotsThreeOutlineBold className="w-4 h-4" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 sm:gap-6">
            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Total
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px]">
                    Total users registered or created by partners
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.users.total}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Pending
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <FcSynchronize className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px] pt-1">
                    users awaiting approval by partners
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.users.pending}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Approved
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-green-100 sm:p-3">
                  <FcCheckmark className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px] pt-1">
                    Total Approved users by partners
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.users.approved}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-3 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                Banned
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-red-100 sm:p-3">
                  <FcCancel className="w-7 h-7" />
                </div>
                <div>
                  <div className="mb-3 text-[10px]">
                    Users banned from services by partners
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.users.banned}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    <div className="flex items-center gap-1 text-[10px]">
                      <div>view</div>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
              <CgToolbox className="w-6 h-6" />
              <div>Services</div>
            </div>
            {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
          </div>
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Loan Service</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.loan}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                      <Link to={"/d/services/loan"}>view</Link>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Savings Service</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.saving}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                      <Link to={"/d/services/savings"}>view</Link>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Approved */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-green-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">
                    Total Investment Service
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.investment}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                      <Link to={"/d/services/investment"}>view</Link>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Banned */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-red-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Payment Service</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.payment}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                      <Link to={"/d/services/payments"}>view</Link>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Bank Accounts</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.bank_account}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                      <Link to={"/d/services/bankaccount"}>view</Link>
                      <FaLongArrowAltRight className="w-2 h-2" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Linked Cards</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.linked_card}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Approved */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-green-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">
                    Total Linked Bank Account
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.linked_bank_account}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/investment"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Banned */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-red-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">
                    Total Transfer Recipient
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.transfer_recipient}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/payments"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Physical Cards</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.physical_card}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <FcViewDetails className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Virtual Cards</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric.service.virtual_card}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
              <CgToolbox className="w-6 h-6" />
              <div>Credit</div>
            </div>
            {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
          </div>
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-green-100 sm:p-3">
                  <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Count</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.credit?.ngn?.counts}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Value</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.credit?.ngn?.value}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <IoLogoUsd className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Count</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.credit?.usd?.value}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <IoLogoUsd className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Value</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.credit?.usd?.value}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
              <CgToolbox className="w-6 h-6" />
              <div>Debit</div>
            </div>
            {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
          </div>
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Count</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.debits?.ngn?.counts}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Pending */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-orange-100 sm:p-3">
                  <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Value</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.debits?.ngn?.value}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <IoLogoUsd className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Count</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.debits?.usd?.value}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
              <div className="text-[12px] mb-4 font-bold inline-block px-0">
                {/* Total */}
              </div>
              <div className="flex items-center gap-3">
                <div className="p-1 bg-sky-100 sm:p-3">
                  <IoLogoUsd className="text-black w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3 text-[10px]">Total Value</div>
                  <div className="flex items-center justify-between">
                    <div className="text-xl text-opacity-80 ">
                      {!isFetching && metric ? (
                        <span>{metric?.debits?.usd?.value}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"50px"} />
                      )}
                    </div>
                    {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center justify-center hidden sm:flex">
          <img src={"/team.svg"} className="w-[400px]" alt="team" />
          <div>
            <div className="mb-5">
              Manage team members, modify roles and permissions.{" "}
            </div>
            <button
              onClick={() => navigate("/d/team")}
              className="border-b-[3px] border-b-purple-800"
            >
              Manage
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 px-5 py-8 min-h-lvh bg-slate-50 dark:bg-neutral-800">
        <div className="mb-10">
          <div className="flex justify-between mb-4 text-sm">
            <div className="font-bold">Team</div>
            <div
              className="underline cursor-pointer"
              onClick={() => navigate("/d/team")}
            >
              See all
            </div>
          </div>
          <div>
            {!isFetching
              ? teams.map((t, i) => (
                  <div key={i} className="flex items-center gap-3 mb-5">
                    {/* <div className='w-[50px] h-[40px] overflow-hidden rounded-full'>
                                        <Image src={'/dummy-profile/image1.avif'} className='w-[45px] rounded-full' alt='photo' width={50} height={50} />
                                    </div> */}
                    <div className="flex justify-center px-2 py-2 rounded-full">
                      <FcBusinessman className="w-7 h-7" />
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="text-xs">
                        <div className="font-bold">{`${t.firstname} ${t.lastname}`}</div>
                        <div className="">{t.email}</div>
                        <div>status: {t.status}</div>
                        <div>role: {t.role}</div>
                        <div>joined: {t.created_on}</div>
                        <div></div>
                      </div>

                      <UpdateTeamModal data={t} updateTeam={updateteam}>
                        <div className="text-xs underline underline-offset-4">
                          manage
                        </div>
                      </UpdateTeamModal>
                    </div>
                  </div>
                ))
              : Array.from(Array(5)).map((k) => (
                  <Skeleton h={"100px"} key={k} className="mb-5" />
                ))}
          </div>
        </div>

        <div className="mb-10">
          <div className="flex items-center gap-3 mb-4 text-sm">
            <div className="font-bold">Top businesses</div>
            <FcComboChart className="w-5 h-5" />
          </div>

          <div className="p-3 mb-5 border-2 rounded-lg shadow-md dark:border-neutral-600">
            <div className="flex gap-3">
              <div className="w-3 h-3 mt-1 bg-orange-400"></div>
              <div>
                <div className="text-xs">
                  Business with the highest number of users
                </div>
                <hr className="mb-3" />
                <div className="flex items-center gap-3">
                  <div className="px-3 py-1 text-lg font-bold rounded-full dark:bg-neutral-700">
                    U
                  </div>
                  <div>
                    <div className="text-sm">
                      {metric && businessHighestUser ? (
                        <span>{businessHighestUser.name}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    <div className="text-xs">
                      Registered:{" "}
                      {metric && businessHighestUser ? (
                        <span>{businessHighestUser.created_on}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    <div className="text-xs">
                      Category:{" "}
                      {metric && businessHighestUser ? (
                        <span>{businessHighestUser.business_category}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    {/* <div className='mb-2 text-xs'>User Inflow: </div> */}
                    <div
                      className="text-[10px] underline underline-offset-4 cursor-pointer"
                      onClick={() =>
                        businessHighestUser &&
                        navigate(
                          `/d/businesses/${businessHighestUser.business_id}/account/details`
                        )
                      }
                    >
                      manage
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-3 mb-5 border-2 rounded-lg shadow-md dark:border-neutral-600">
            <div className="flex gap-3">
              <div className="w-3 h-3 mt-1 bg-purple-400"></div>
              <div>
                <div className="text-xs">Business with the highest inflow</div>
                <hr className="mb-3" />
                <div className="flex items-center gap-3">
                  <div className="px-3 py-1 text-lg font-bold rounded-full dark:bg-neutral-700">
                    B
                  </div>
                  <div>
                    <div className="text-sm">
                      {metric && businessInflow ? (
                        <span>{businessInflow.name}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    <div className="text-xs">
                      Registered:{" "}
                      {metric && businessInflow ? (
                        <span>{businessInflow.created_on}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    <div className="text-xs">
                      Category:{" "}
                      {metric && businessInflow ? (
                        <span>{businessInflow.business_category}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    {/* <div className='mb-2 text-xs'>Cash Inflow: NGN 1,267,289</div> */}
                    <div
                      className="text-[10px] underline underline-offset-4 cursor-pointer"
                      onClick={() =>
                        businessInflow &&
                        navigate(
                          `/d/businesses/${businessInflow.business_id}/account/details`
                        )
                      }
                    >
                      manage
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-3 mb-5 border-2 rounded-lg shadow-md dark:border-neutral-600">
            <div className="flex gap-3">
              <div className="w-3 h-3 mt-1 bg-orange-400"></div>
              <div>
                <div className="text-xs">Business with the highest outflow</div>
                <hr className="mb-3" />
                <div className="flex items-center gap-3">
                  <div className="px-3 py-1 text-lg font-bold rounded-full dark:bg-neutral-700">
                    B
                  </div>
                  <div>
                    <div className="text-sm">
                      {metric && businessOutflow ? (
                        <span>{businessOutflow.name}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    <div className="text-xs">
                      Registered:{" "}
                      {metric && businessOutflow ? (
                        <span>{businessOutflow.created_on}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    <div className="text-xs">
                      Category:{" "}
                      {metric && businessOutflow ? (
                        <span>{businessOutflow.business_category}</span>
                      ) : (
                        <Skeleton h={"20px"} w={"150px"} />
                      )}
                    </div>
                    {/* <div className='mb-2 text-xs'>Cash Inflow: NGN 1,107,990</div> */}
                    <div
                      className="text-[10px] underline underline-offset-4 cursor-pointer"
                      onClick={() =>
                        businessOutflow &&
                        navigate(
                          `/d/businesses/${businessOutflow.business_id}/account/details`
                        )
                      }
                    >
                      manage
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex items-center gap-3 mb-4 text-sm">
            <div className="font-bold">Last broadcasted notification</div>
            <FcAdvertising className="w-5 h-5" />
          </div>
        </div>

        <div className="h-1"></div>
      </div>
    </div>
  );
}

export default OverviewClient;
