"use client"

import React, { createContext, useContext, useEffect, useState } from "react"
import { IUser } from "@/types/user.type";
import { AuthCred, IAppContext } from "@/types/global.types";
import http from "@/lib/http";

export const AppContext = createContext<IAppContext | undefined>(undefined);

function AppProvider({ children }: { children: React.ReactNode }) {

  const [user, setUser] = useState<IUser | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authCred, setAuthCred] = useState<AuthCred | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  async function getUser() {
    setIsAuthenticating(true);
    try {
      const resp = await http("account/details");
      setUser({
        business_team_id: resp.data.business_team_id,
        account_connect_id: resp.data.account_connect_id,
        firstname: resp.data.firstname,
        lastname: resp.data.lastname,
        email: resp.data.email,
        phone: resp.data.phone,
        photo: resp.data.photo,
        status: resp.data.status,
        active: resp.data.active,
        role: resp.data.role,
        created_on: resp.data.created_on
      })
      setIsAuthenticated(true);
      console.log(resp.data)
      setIsAuthenticating(false);
    } catch (error) {
      console.log(error);
      setIsAuthenticating(false);
    } 
  }

  useEffect(() => {
    // if (getToken()) {
    //   getUser();
    // } else {
    //   console.log("no token");
    //   setIsAuthenticating(false);
    // }
    getUser();
  }, []);  

  async function logout() {
    setIsAuthenticating(true);
    try {
        await http("account/logout");
        localStorage.removeItem('app-tkn');
        setIsAuthenticated(false);
        setUser(null);
    } catch (error) {
        console.log(error)
        setIsAuthenticating(false);
    }
}

  return (
    <AppContext.Provider value={{
      isMounted,
      user,
      setUser,
      isAuthenticating,
      isAuthenticated,
      setIsAuthenticated,
      authCred,
      setAuthCred,
      logout
    }}>
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  return useContext<IAppContext | undefined>(AppContext)!;
}

export default AppProvider