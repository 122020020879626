'use client'

import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useState } from 'react'
// import { FaEdit } from 'react-icons/fa'
import { FcManager } from 'react-icons/fc'
import { ImSpinner8 } from 'react-icons/im';

function AccountDetailsClient() {

  const { user, setUser } = useApp();
  const toast = useToast();

  const [tab, setTab] = useState<"details" | "update">("details");
  const [updateFirstname, setUpdateFirstname] = useState<string>(user ? user.firstname : "");
  const [updateLastname, setUpdateLastname] = useState<string>(user ? user.lastname : "");
  const [updateEmail, setUpdateEmail] = useState<string>(user ? user.email : "");
  const [updatePhone, setUpdatePhone] = useState<string>(user ? user.phone : "");
  const [updatePassword, setUpdatePassword] = useState<string>("");
  const [isFetchingName, setIsFetchingName] = useState(false);
  const [isFetchingEmail, setIsFetchingEmail] = useState(false);
  const [isFetchingPhone, setIsFetchingPhone] = useState(false);
  const [isFetchingPassword, setIsFetchingPassword] = useState(false);

  async function updateAccountEmail() {
    setIsFetchingEmail(true);
    try {
      const resp = await http("account/update/change-email", { email: updateEmail });
      console.log("resp", resp.data)
      setUser((u) => u ? ({ ...u, email: resp.data.email }) : null)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetchingEmail(false);
    } catch (error) {
      console.log(error)
      setIsFetchingEmail(false);
      handleResponseError(error, toast);
    }
  }
  
  async function updateAccountPhone() {
    setIsFetchingPhone(true);
    try {
      const resp = await http("account/update/change-phone", { phone: updatePhone });
      console.log("resp", resp.data)
      setUser((u) => u ? ({ ...u, phone: resp.data.phone }) : null)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetchingPhone(false);
    } catch (error) {
      console.log(error)
      setIsFetchingPhone(false);
      handleResponseError(error, toast);
    }
  }

  async function updateAccountName() {
    setIsFetchingName(true);
    try {
      const resp = await http("account/update/change-name", { firstname: updateFirstname, lastname: updateLastname });
      console.log("resp", resp.data)
      setUser((u) => u ? ({ ...u, firstname: resp.data.firstname, lastname: resp.data.lastname }) : null)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetchingName(false);
    } catch (error) {
      console.log(error)
      setIsFetchingName(false);
      handleResponseError(error, toast);
    }
  }

  async function updateAccountPassword() {
    setIsFetchingPassword(true);
    try {
      const resp = await http("account/update/change-password", { password: updatePassword });
      console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetchingPassword(false);
    } catch (error) {
      console.log(error)
      setIsFetchingPassword(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <div className='w-full px-5 py-8 sm:px-10'>

      <div className='mb-8 text-xl'>Account Details</div>
      <hr className='mb-10 border-slate-300 dark:border-neutral-700' />

      <div className='w-full mb-10'>

        <div className='flex items-center justify-between mb-10'>
          <div className='flex items-center gap-3'>
            <div className='p-4 bg-purple-800 rounded-full'>
              <FcManager className='w-7 h-7' />
            </div>
            <div>
              <div className='text-sm font-bold'>{`${user?.firstname} ${user?.lastname}`}</div>
              <div className='text-xs text-neutral-500'>Role: {`${user?.role}`}</div>
            </div>
          </div>
        </div>

        <div className='flex gap-5 mb-8'>
          <button className={classNames('text-[13px]', {
            'text-purple-600 underline font-bold': tab === "details",
            '': tab === "update"
          })} onClick={() => { setTab("details") }}>Details</button>
          <button className={classNames('text-[13px]', {
            'text-purple-600 underline font-bold': tab === "update",
            '': tab === "update"
          })} onClick={() => { setTab("update") }}>Update</button>
        </div>

        {tab === "details" ?
          <div className='mb-14'>
            <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold'>Account information</div>

            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4'>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Firatnsme</div>
                <div className='text-sm'>{`${user?.firstname}`}</div>
              </div>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Lastname</div>
                <div className='text-sm'>{`${user?.lastname}`}</div>
              </div>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Email</div>
                <div className='text-sm'>{`${user?.email}`}</div>
              </div>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Phone</div>
                <div className='text-sm'>{`${user?.phone}`}</div>
              </div>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Status</div>
                <div className='text-sm'>{`${user?.status}`}</div>
              </div>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Active</div>
                <div className='text-sm'>{`${user?.active}`}</div>
              </div>
              <div>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>Registered</div>
                <div className='text-sm'>{`${user?.created_on}`}</div>
              </div>
            </div>
          </div> :
          <div>
            <div className='mb-8'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Update Name</div>
              <div className='grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4'>
                <div className=''>
                  <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Firstname</div>
                  <input type="text" value={updateFirstname} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateFirstname(e.target.value)} placeholder='' disabled={isFetchingName} />
                </div>
                <div className=''>
                  <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Lastname</div>
                  <input type="text" value={updateLastname} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateLastname(e.target.value)} placeholder='' disabled={isFetchingName} />
                </div>
              </div>
              <div>
                <button className='flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50' onClick={() => updateAccountName()} disabled={isFetchingName}>
                  {
                    isFetchingName ? <ImSpinner8 className='w-5 h-5 animate-spin' /> : <span>Submit</span>
                  }
                </button>
              </div>
            </div>

            <div className='mb-8'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Update Email</div>
              <div className='grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4'>
                <div className=''>
                  <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                  <input type="text" value={updateEmail} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateEmail(e.target.value)} placeholder='' disabled={isFetchingEmail} />
                </div>
              </div>
              <div>
                <button className='flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50' onClick={() => updateAccountEmail()} disabled={isFetchingEmail}>
                  {
                    isFetchingEmail ? <ImSpinner8 className='w-5 h-5 animate-spin' /> : <span>Submit</span>
                  }
                </button>
              </div>
            </div>

            <div className='mb-8'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Update Phone</div>
              <div className='grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4'>
                <div className=''>
                  <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                  <input type="text" value={updatePhone} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdatePhone(e.target.value)} placeholder='' disabled={isFetchingPhone} />
                </div>
              </div>
              <div>
                <button className='flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50' onClick={() => updateAccountPhone()} disabled={isFetchingPhone}>
                  {
                    isFetchingPhone ? <ImSpinner8 className='w-5 h-5 animate-spin' /> : <span>Submit</span>
                  }
                </button>
              </div>
            </div>

            <div className='mb-8'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Update password</div>
              <div className='grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4'>
                <div className=''>
                  <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Password</div>
                  <input type="text" className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdatePassword(e.target.value)} placeholder='' disabled={isFetchingPassword} />
                </div>
              </div>
              <div>
                <button className='flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50' onClick={() => updateAccountPassword()} disabled={isFetchingPassword}>
                  {
                    isFetchingPassword ? <ImSpinner8 className='w-5 h-5 animate-spin' /> : <span>Submit</span>
                  }
                </button>
              </div>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default AccountDetailsClient