'use client'

import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useLocation } from 'react-router-dom';

function UpdateBusinessCommissionModal({
  children,
  data,
  updateCommission
}: {
  children: React.ReactNode;
  data: any;
  updateCommission: (commission_id: string, newActive: string, rate: string, rateCapped: string) => void
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();
  const {pathname} = useLocation();

  const [active, setActive] = useState<string>(data.active);
  const [rate, setRate] = useState<string>(data.rate);
  const [rateCapped, setRateCapped] = useState<string>(data.rate_capped);
  const [isFetching, setIsFetching] = useState(false);

  async function commissionUpdate() {
    setIsFetching(true);
    try {
      const resp = await http("business/commission/update", { business_id: getBusinessId(), commission_id: data.commission_id, active, rate, rateCapped });
      console.log("resp", resp.data)
      updateCommission(data.commission_id, active, rate, rateCapped)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  function getBusinessId() {
    const split_path = pathname.split('/');
    return split_path[3];
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Update commission</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <div className='mb-3'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{data.slug}</div>

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Active</div>
                <select value={active} onChange={(e) => setActive(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching}>
                  <option value="">Select an option</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Rate</div>
                <input type='text' value={rate} onChange={(e) => setRate(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Rate Capped</div>
                <input type='text' value={rateCapped} onChange={(e) => setRateCapped(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>

              <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => commissionUpdate()} disabled={isFetching}>
                {
                  isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                }
              </button>
            </div>
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateBusinessCommissionModal