"use client";

import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
// import { FaEdit } from "react-icons/fa";
import { FcShop } from "react-icons/fc";
import http, { httpRaw } from "@/lib/http";
import { IBusiness, IBusinessUpdate } from "@/types/user.type";
import { Skeleton, useToast } from "@chakra-ui/react";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";
import { useLocation } from "react-router-dom";

function BusinessAccountDetailsClient() {
  const [business, setBusiness] = useState<IBusiness | null>(null);
  const { pathname } = useLocation();
  const toast = useToast();

  const [isFetching, setIsFetching] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [tab, setTab] = useState<"details" | "update">("details");
  // const [isFetchingStates, setIsFetchingStates] = useState(true);
  // const [isFetchingLgas, setIsFetchingLgas] = useState(false);
  const [businessCategories, setBusinessCategories] = useState<
    {
      business_category_id: string;
      business_category: string;
    }[]
  >([]);
  const [states, setStates] = useState<
    {
      state_id: string;
      state_name: string;
    }[]
  >([]);
  const [lgas, setLgas] = useState<
    {
      lga_id: string;
      state_id: string;
      country_code: string;
      lga_name: string;
    }[]
  >([]);

  const [updateBusiness, setUpdateBusiness] = useState<IBusinessUpdate>({
    name: "",
    business_category_id: "",
    email: "",
    phone: "",
    short_name: "",
    primary_contact: {
      email: "",
      phone: "",
      lastname: "",
      firstname: "",
    },
    address: "",
    state_id: "",
    lga_id: "",
    city: "",
  });

  async function fetchBusiness() {
    try {
      const resp = await http("business/account/details", {
        business_id: getBusinessId(),
      });
      console.log("resp", resp);
      if (resp.data.length > 0) {
        setBusiness(resp.data[0]);
        setUpdateBusiness({
          name: resp.data[0].name,
          business_category_id: resp.data[0].business_category_id,
          email: resp.data[0].email,
          phone: resp.data[0].phone,
          short_name: resp.data[0].short_name,
          address: resp.data[0].address,
          state_id: resp.data[0].state_id ? resp.data[0].state_id : "",
          lga_id: resp.data[0].lga_id ? resp.data[0].lga_id : "",
          city: resp.data[0].city,
        });
      }

      setIsFetching(false);
    } catch (error) {
      console.log(error);
      // setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getBusinessId() {
    const split_path = pathname.split("/");
    return split_path[3];
  }

  async function getStates() {
    try {
      const resp = await httpRaw(
        "https://dev.lucrestack.co/misc/state",
        { country_code: "NG" },
        "pk_73122563bee4bc7cb24075bef6d02098"
      );
      console.log("states", resp.data);
      setStates(resp.data);
      // setIsFetchingStates(false);
    } catch (error) {
      console.log(error);
      // setIsFetchingStates(false);
      handleResponseError(error, toast);
    }
  }

  async function getBusinessCategories() {
    try {
      const resp = await httpRaw(
        "https://dev.lucrestack.co/misc/business-category",
        {},
        "pk_73122563bee4bc7cb24075bef6d02098"
      );
      console.log("states", resp.data);
      setBusinessCategories(resp.data);
      // setIsFetchingStates(false);
    } catch (error) {
      console.log(error);
      // setIsFetchingStates(false);
      handleResponseError(error, toast);
    }
  }

  async function getLgas() {
    // setIsFetchingLgas(true);
    setLgas([]);
    try {
      const resp = await httpRaw(
        "https://dev.lucrestack.co/misc/lga",
        { state_id: updateBusiness.state_id },
        "pk_73122563bee4bc7cb24075bef6d02098"
      );
      console.log("lgas", resp.data);
      setLgas(resp.data);
      // setIsFetchingLgas(false);
    } catch (error) {
      console.log(error);
      // setIsFetchingLgas(false);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getStates();
    getBusinessCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("state_id", updateBusiness.state_id);
    if (updateBusiness.state_id.length > 0) {
      getLgas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBusiness.state_id]);

  async function updateAccount() {
    setIsSending(true);
    try {
      const resp = await http("business/account/update", {
        business_id: getBusinessId(),
        ...updateBusiness,
      });
      console.log("update resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div>Businesses</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Account</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Details</div>
        </div>
      </div>

      <div className="mb-8 text-xl">Account Details</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      <div className="w-full mb-10">
        <div className="flex items-center justify-between mb-10">
          <div className="flex items-center gap-3">
            <div className="p-4 bg-purple-800 rounded-full">
              <FcShop className="w-7 h-7" />
            </div>
            <div className="text-sm font-bold">
              {business ? (
                <span>{business.name}</span>
              ) : (
                <Skeleton w={"100px"} h={"20px"} />
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-5 mb-8">
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "details",
              "": tab === "update",
            })}
            onClick={() => {
              setTab("details");
            }}
          >
            Details
          </button>
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "update",
              "": tab === "update",
            })}
            onClick={() => {
              setTab("update");
            }}
          >
            Update
          </button>
        </div>

        {tab === "details" ? (
          <>
            <div className="mb-14">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Business information
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Name
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.name}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Short name
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.short_name}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    email
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.email}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    phone
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.phone}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    country code
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.country_code}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    business category
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.business_category}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    status
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.status}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    subscription status
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.subscription_status}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Subscription timestamp
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.subscription_timestamp}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    active
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.active}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    created_on
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.created_on}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-10">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Account officer
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    firstname
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.account_officer.firstname}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    lastname
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.account_officer.lastname}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    middlename
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.account_officer.middlename}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mb-8">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                Business Info{" "}
                {isFetching && (
                  <ImSpinner8 className="inline-block w-4 h-4 animate-spin" />
                )}
              </div>
              <div className="grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4">
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Email
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.email}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        email: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Name
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.name}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({ ...b, name: e.target.value }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Short name
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.short_name}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        short_name: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Phone
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.phone}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        phone: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Category
                  </div>
                  <select
                    value={updateBusiness.business_category_id}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        business_category_id: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="">Select</option>
                    {businessCategories.map((e, i) => (
                      <option key={i} value={e.business_category_id}>
                        {e.business_category}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Primary contact</div>
                            <div className='grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4'>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                                    <input type="text" value={updateBusiness.primary_contact.email} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, email: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                                    <input type="text" value={updateBusiness.primary_contact.phone} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, phone: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Firstname</div>
                                    <input type="text" value={updateBusiness.primary_contact.firstname} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, firstname: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Lastname</div>
                                    <input type="text" value={updateBusiness.primary_contact.lastname} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, lastname: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                            </div> */}

              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                Location
              </div>
              <div className="grid grid-cols-1 gap-5 mb-10 md:grid-cols-2 lg:grid-cols-4">
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Address
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.address}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        address: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    State
                  </div>
                  <select
                    value={updateBusiness.state_id}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        state_id: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="">Select</option>
                    {states.map((e, i) => (
                      <option key={i} value={e.state_id}>
                        {e.state_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    City
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.city}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({ ...b, city: e.target.value }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    LGA
                  </div>
                  <select
                    value={updateBusiness.city}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        lga_id: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="">Select</option>
                    {lgas.map((e, i) => (
                      <option key={i} value={e.lga_id}>
                        {e.lga_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => updateAccount()}
                  disabled={isSending}
                >
                  {isSending ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default BusinessAccountDetailsClient;
