'use client'

import React, { useState } from 'react'
import ThemeButton from './ThemeButton'
import { useNavigate, useLocation } from 'react-router-dom';
import { HiOutlinePuzzle } from "react-icons/hi";
import { FaAngleLeft } from "react-icons/fa6";
import { GrOverview } from "react-icons/gr";
import { MdAddBusiness } from "react-icons/md";
import { PiUsersThreeBold } from "react-icons/pi";
import { FaRegAddressCard } from "react-icons/fa";
import { CgToolbox } from "react-icons/cg";
import { RiMailSendLine } from "react-icons/ri";
import { GrUserSettings } from "react-icons/gr";
import classNames from 'classnames';
import { upperCaseFirst } from '@/utils/helpers';
import { MdExitToApp } from "react-icons/md";
import { useApp } from '@/contexts/appContext';

function PanelHeader() {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const {logout} = useApp();

    function getPath() {
        const split_path = pathname.split('/');
        return split_path[2];
    }

    function navigateToPage(href: string) {
        if (href.length !== 0) {
            navigate(href);
            setIsMenuOpen(false);
        }
    }

    // console.log(getPath())

    async function logoutFunc() {
        try {
            await logout();
            navigate('/auth/login', {replace: true});
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className='flex justify-between items-center bg-neutral-50 dark:bg-neutral-900 font-container'>
                <div className='px-5 sm:py-2 py-2 sm:w-[300px] w-[200px] sm:min-h-[65px] flex justify-between items-center border-r-[1px] border-r-neutral-200 dark:border-r-neutral-800'>
                    <div className='cursor-pointer' onClick={() => { navigate('/d/overview') }}>
                        <img src={'/logo.png'}
                            className='w-[100px]' alt='logo' />
                    </div>
                    {/* <div>
                        <div className='flex font-extrabold mb-[1px] text-sm'>
                            <div className='text-purple-800 border-b-4 border-b-purple-800'>LUCRE</div>
                            <div className='text-yellow-600 border-b-4 border-b-yellow-600'>STACK</div>
                        </div>
                        <div className='text-[10px] font-bold dark:text-neutral-100 text-neutral-800'>Manager portal</div>
                    </div> */}
                    <ThemeButton />
                </div>
                <div className='relative'>
                    <div className='flex gap-2 px-5 items-center cursor-pointer' onClick={() => { isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true) }}>
                        <div className='flex items-center'>
                            <div className='flex items-center gap-2 pl-4 pr-10 py-1 text-neutral-800 dark:text-neutral-100 rounded-l-2xl text-[11px] -mr-7 relative font-extrabold'>
                                <FaAngleLeft className='w-4 h-4' />
                                <div>{getPath().length > 0 && upperCaseFirst(getPath())}</div>
                            </div>
                            <div className='rounded-full md:p-2 p-1 bg-slate-900 relative z-10 md:border-[3px] border-neutral-100'>
                                <HiOutlinePuzzle className='text-neutral-100 md:w-5 w-5 md:h-5 h-5' />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('absolute px-3 py-3 dark:bg-neutral-900 border-2 bg-neutral-100 dark:border-neutral-700 border-neutral-300 w-[300px] right-[10px] top-12 z-20', {
                        'block': isMenuOpen,
                        'hidden': !isMenuOpen
                    })}>
                        <div className='grid grid-cols-3 gap-5'>
                            <div onClick={() => navigateToPage('/d/overview')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "overview",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "overview"
                                    })}>
                                        <GrOverview className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Overview</div>
                                </center>
                            </div>

                            <div onClick={() => navigateToPage('/d/businesses')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "businesses",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "businesses"
                                    })}>
                                        <MdAddBusiness className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Businesses</div>
                                </center>
                            </div>

                            <div onClick={() => navigateToPage('/d/team')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "team",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "team"
                                    })}>
                                        <PiUsersThreeBold className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Team</div>
                                </center>
                            </div>

                            <div onClick={() => navigateToPage('/d/kyc')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "kyc",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "kyc"
                                    })}>
                                        <FaRegAddressCard className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>KYC</div>
                                </center>
                            </div>

                            <div onClick={() => navigateToPage('/d/services')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "services",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "services"
                                    })}>
                                        <CgToolbox className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Services</div>
                                </center>
                            </div>

                            <div onClick={() => navigateToPage('/d/notification')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "notification",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "notification"
                                    })}>
                                        <RiMailSendLine className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Notification</div>
                                </center>
                            </div>

                            <div onClick={() => navigateToPage('/d/account')} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "account",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "account"
                                    })}>
                                        <GrUserSettings className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Account</div>
                                </center>
                            </div>

                            <div onClick={() => {logoutFunc()}} className='dark:text-neutral-100 cursor-pointer'>
                                <center className='child'>
                                    <div className={classNames('rounded-full inline-block p-3 hover:bg-purple-900 dark:hover:bg-purple-900 hover:text-neutral-50 ', {
                                        'bg-purple-900 dark:bg-purple-900 text-neutral-100 dark:text-neutral-100': getPath() === "logout",
                                        'dark:bg-neutral-700 bg-slate-200 text-neutral-700 dark:text-neutral-200': getPath() !== "logout"
                                    })}>
                                        <MdExitToApp className='w-5 h-5' />
                                    </div>
                                    <div className='text-xs'>Logout</div>
                                </center>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-r from-purple-800 via-purple-400 via-10% to-yellow-500  h-[7px] w-full"></div>
        </>
    )
}

export default PanelHeader