'use client'

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import Loader from './Loader';
import BusinessesLayout from './BusinessesLayout';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';

function BusinessLayoutClient() {

    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(true);
    const [business, setBusiness] = useState<string | null>(null);
    const toast = useToast();
    const {pathname} = useLocation();

    async function getBusiness() {
        try {
            const resp = await http("business/account/details", {business_id: getBusinessId()});
            setBusiness(resp.data[0].name);
            console.log(resp.data[0])
            setIsFetching(false);
        } catch (error) {
            navigate('/d/overview', {replace: true});
            handleResponseError(error, toast);
        }
    }

    function getBusinessId() {
        const split_path = pathname.split('/');
        return split_path[3];
    }

    useEffect(() => {
        getBusiness();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!isFetching ?
                <div className='md:flex'>
                    <BusinessesLayout name={business} />
                    <div className='p-5 md:flex-1'><Outlet /></div>
                </div> : <Loader />}
        </>
    )
}

export default BusinessLayoutClient