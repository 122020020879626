'use client'

import React, { useEffect, useMemo, useState } from 'react'
import http from '@/lib/http';

import { MdAddBusiness } from "react-icons/md";
import { Skeleton } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

function SelectBusinessClient() {

    const [isFetching, setIsFetching] = useState(true);
    const [businesses, setBusinesses] = useState([]);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");

    async function fetchBusinesses() {
        try {
            const resp = await http("business/account/details");
            console.log("resp", resp.data)
            setBusinesses(resp.data)
            setIsFetching(false);
        } catch (error) {
            console.log(error)
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchBusinesses();
    }, []);

    const filtered = useMemo(() => {
        if (businesses.length === 0) return [];
        //  if(search === "");
        const sorted_ = sort === "date-asc" ?
            businesses.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
            sort === "date-desc" ?
                businesses.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
                sort === "z-a" ?
                    businesses.slice().sort((a: any, b: any) => b.name.localeCompare(a.name)) :
                    businesses.slice().sort((a: any, b: any) => a.name.localeCompare(b.name));

        const filtered_ = search.length > 0 ?
            sorted_.filter((e: any) => e.name.toLowerCase().includes(search.toLowerCase())) :
            sorted_;

        return filtered_;

    }, [search, sort, businesses]);

    return (
        <>
            <div className='sm:px-10 px-5 py-8 w-full'>
                <div className='text-xl font-bold mb-8'>Select Business</div>

                <div className='pb-10'>
                    <div className='mb-8 flex gap-5 items-center'>
                        <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
                        <div className='flex-1'>
                            <div className='text-xs'>Sort by</div>
                            <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
                                <option value="a-z">A-Z</option>
                                <option value="z-a">Z-A</option>
                                <option value="date-asc">Date (Ascending)</option>
                                <option value="date-desc">Date (Descending)</option>
                            </select>
                        </div>
                    </div>

                    <hr className='mb-6 border-slate-300 dark:border-neutral-700' />

                    <div className='flex w-full justify-between mb-6 items-center'>
                        <div></div>
                        <div className='flex gap-3 text-purple-500'>
                            <MdAddBusiness />
                            <Link to={'/d/businesses/create'} className='text-xs'>Create business</Link>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
                        {!isFetching ?
                            filtered.map((b: any, i) => (
                                <Link key={i} to={`/d/businesses/${b.business_id}/overview`} className='p-5 dark:bg-neutral-700 bg-slate-50` flex gap-5 cursor-pointer border-2 border-neutral-100 shadow-md dark:border-neutral-500 hover:border-purple-700 dark:hover:border-purple-700'>
                                    <div>
                                        <div className='px-3 py-2 bg-purple-900 font-bold rounded-full text-neutral-100'>U</div>
                                    </div>
                                    <div>
                                        <div className='font-bold mb-1'>{b.name}</div>
                                        <div className='text-xs'>{b.email}</div>
                                        <div className='text-xs flex gap-1'>
                                            <div className='font-bold text-neutral-400'>subscription status:</div>
                                            <div>{b.status}</div>
                                        </div>
                                        <div className='text-xs flex gap-1'>
                                            <div className='font-bold text-neutral-400'>created:</div>
                                            <div>{b.created_on}</div>
                                        </div>
                                    </div>
                                </Link>
                            )) :
                            Array.from(Array(8)).map((k) => (
                                <Skeleton h={'130px'} key={k} />
                            ))
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectBusinessClient