"use client";

import React, { useEffect, useMemo, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import http from "@/lib/http";
import { Skeleton } from "@chakra-ui/react";
import UpdateBusinessCommissionModal from "@/components/modals/UpdateBusinessCommissionModal";
import AddCommissionModal from "@/components/modals/AddCommissionModal";
import { useLocation } from "react-router-dom";
import AutoGenNormalViewModal from "@/components/modals/AutoGenNormalViewModal";
import FilterForm from "@/components/FilterForm";

function BusinessCommissionDetailsClient() {
  const [details, setDetails] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const { pathname } = useLocation();

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    user_id: "",
    active: "",
    commission_id: "",
    order_by: "",
    business_id: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchServices() {
    try {
      const resp = await http("business/commission/details", {
        business_id: getBusinessId(),
        limit: 10,
      });
      console.log("resp", resp);
      setDetails(resp.data);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  function updateCommission(
    commission_id: string,
    newActive: string,
    rate: string,
    rateCapped: string
  ) {
    const index = details.findIndex(
      (commission) => commission.commission_id === commission_id
    );
    if (index !== -1) {
      const updatedCommissions = [...details];
      updatedCommissions[index].active = newActive;
      updatedCommissions[index].rate = rate;
      updatedCommissions[index].rate_capped = rateCapped;
      setDetails(updatedCommissions);
    }
  }

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDetails = useMemo(() => {
    if (details.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? details
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? details
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? details
            .slice()
            .sort((a: any, b: any) =>
              b.user.firstname.toLowerCase() < a.user.firstname.toLowerCase()
                ? -1
                : 1
            )
        : details
            .slice()
            .sort((a: any, b: any) =>
              a.user.firstname.toLowerCase() < b.user.firstname.toLowerCase()
                ? -1
                : 1
            );

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            `${e.user.firstname} ${e.user.lastname}`
              .toLowerCase()
              .includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, details]);

  function getBusinessId() {
    const split_path = pathname.split("/");
    return split_path[3];
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div>Businesses</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Commission</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Details</div>
        </div>
      </div>

      <div className="mb-10 text-xl">Commission Details</div>

      <div className="flex items-center gap-5 mb-8">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-6 border-slate-300 dark:border-neutral-700" />

      <FilterForm
        url="business/commission/details"
        payload={{ business_id: getBusinessId() }}
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setDetails}
        setPaginationInput={setPaginationInput}
      />

      <div className="flex justify-between mb-8">
        <div></div>
        <AddCommissionModal
          business_id={getBusinessId()}
          setDetails={setDetails}
        >
          <div className="text-sm text-purple-500">Add commission</div>
        </AddCommissionModal>
      </div>

      <div className="w-full mb-10">
        <div className="relative overflow-x-auto">
          <table className="w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="text-[11px] text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Business Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Business Name
                </th>
                <th scope="col" className="px-6 py-3">
                  User Fullname
                </th>
                <th scope="col" className="px-6 py-3">
                  User Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate Capped
                </th>
                <th scope="col" className="px-6 py-3">
                  Active
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching
                ? filteredDetails.map((d, k) => (
                    <tr
                      key={k}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {d?.business?.id}
                      </th>
                      <td className="px-6 py-4">{d?.business?.name}</td>
                      <td className="px-6 py-4">
                        {d?.user?.firstname} {d?.user?.lastname}
                      </td>
                      <td className="px-6 py-4">{d?.user?.email}</td>
                      <td className="px-6 py-4">{d.rate}</td>
                      <td className="px-6 py-4">{d.rate_capped}</td>
                      <td className="px-6 py-4">{d.active}</td>
                      <td className="flex gap-3 px-6 py-4">
                        <AutoGenNormalViewModal title="Commission" data={d}>
                          <span className="font-bold text-blue-600">View</span>
                        </AutoGenNormalViewModal>
                        <UpdateBusinessCommissionModal
                          data={d}
                          updateCommission={updateCommission}
                        >
                          <span className="font-bold text-blue-600">
                            Update
                          </span>
                        </UpdateBusinessCommissionModal>
                      </td>
                    </tr>
                  ))
                : Array.from(Array(5)).map((k, i) => (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BusinessCommissionDetailsClient;
