'use client'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoReturnUpBackSharp } from "react-icons/io5";
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { FaCreditCard } from 'react-icons/fa6';
import { GrOverview } from "react-icons/gr";
import { CgToolbox } from "react-icons/cg";
import { GrUserSettings } from "react-icons/gr";
import { PiUsersThreeBold } from "react-icons/pi";
import { GiPayMoney } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import BusinessMobileDrawer from './drawers/BusinessMobileDrawer';

function BusinessMenu({isMobile, name}:{isMobile: boolean, name:string | null}) {

    const { pathname } = useLocation();
    const [isCommissionTabOpened, setIsCommissionTabOpened] = useState(false);
    const [isAccountTabOpened, setIsAccountTabOpened] = useState(false);
    const [isServicesTabOpened, setIsServicesTabOpened] = useState(false);
    const [isWalletTabOpened, setIsWalletTabOpened] = useState(false);
    const [isSubscriptonTabOpened, setIsSubscriptonTabOpened] = useState(false);

    function getPath() {
        const split_path = pathname.split('/');
        return split_path[4];
    }

    function getSubPath() {
        const split_path = pathname.split('/');
        return split_path[5];
    }

    function getBusinessId() {
        const split_path = pathname.split('/');
        return split_path[3];
    }

    return (
        <>
            <div className={classNames('min-h-lvh dark:bg-neutral-800 ', {
                'hidden md:block w-[250px] bg-slate-50 pt-10': !isMobile,
                'block w-full': isMobile
            })}>

                <div className={classNames('p-8', {
                    'py-8': !isMobile,
                    'py-0': isMobile
                })}>
                    <div className='text-xs text-slate-500 dark:text-neutral-400 flex justify-between items-center'>
                        <Link to='/d/businesses' className='flex gap-2 items-center'>
                            <IoReturnUpBackSharp />
                            <div>Exit</div>
                        </Link>
                        <div>Business</div>
                    </div>
                    <div className='flex flex-col items-end'>
                        <div className='font-bold mb-10 text-sm'>{name && name}</div>
                    </div>
                </div>

                <div className='flex flex-col items-end'>
                    <Link to={`/d/businesses/${getBusinessId()}/overview`} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "overview",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "overview" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Overview</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "overview",
                                'bg-neutral-300': getPath() !== "overview",
                            })}>
                                <GrOverview className={classNames({
                                    'text-neutral-100': getPath() === "overview",
                                    'text-neutral-900': getPath() !== "overview",
                                })} />
                            </div>
                        </div>
                    </Link>
                    {/* to={`/d/businesses/${getBusinessId()}/wallet`} */}
                    <button onClick={() => isWalletTabOpened ? setIsWalletTabOpened(false) : setIsWalletTabOpened(true)} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "wallet",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "wallet" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Wallet</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "wallet",
                                'bg-neutral-300': getPath() !== "wallet",
                            })}>
                                <FaCreditCard className={classNames({
                                    'text-neutral-100': getPath() === "wallet",
                                    'text-neutral-900': getPath() !== "wallet",
                                })} />
                            </div>
                        </div>
                    </button>

                    {isWalletTabOpened && <div className='bg-slate-200 dark:bg-neutral-700 flex flex-col items-end px-8 w-full'>
                        <Link to={`/d/businesses/${getBusinessId()}/wallet/details`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "wallet" && getSubPath() === "details",
                            })}>Details</div>
                        </Link>
                        <Link to={`/d/businesses/${getBusinessId()}/wallet/transactions`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "wallet" && getSubPath() === "transactions",
                            })}>Transactions</div>
                        </Link>
                    </div>}

                    <button onClick={() => isServicesTabOpened ? setIsServicesTabOpened(false) : setIsServicesTabOpened(true)} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "services",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "services" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Services</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "services" || isServicesTabOpened,
                                'bg-neutral-300': getPath() !== "services" && !isServicesTabOpened,
                            })}>
                                <CgToolbox className={classNames({
                                    'text-neutral-100': getPath() === "services" || isServicesTabOpened,
                                    'text-neutral-900': getPath() !== "services" && !isServicesTabOpened,
                                })} />
                            </div>
                        </div>
                    </button>

                    {isServicesTabOpened && <div className='bg-slate-200 dark:bg-neutral-700 flex flex-col items-end px-8 w-full'>
                        <Link to={`/d/businesses/${getBusinessId()}/services/details`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "services" && getSubPath() === "details",
                            })}>Details</div>
                        </Link>
                        <Link to={`/d/businesses/${getBusinessId()}/services/transactions`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "services" && getSubPath() === "transactions",
                            })}>Transactions</div>
                        </Link>
                    </div>}

                    <Link to={`/d/businesses/${getBusinessId()}/team`} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "team",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "team" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Team</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "team",
                                'bg-neutral-300': getPath() !== "team",
                            })}>
                                <PiUsersThreeBold className={classNames({
                                    'text-neutral-100': getPath() === "team",
                                    'text-neutral-900': getPath() !== "team",
                                })} />
                            </div>
                        </div>
                    </Link>

                    <Link to={`/d/businesses/${getBusinessId()}/users`} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "users",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "users",
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Users</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "users",
                                'bg-neutral-300': getPath() !== "users",
                            })}>
                                <PiUsersThreeBold className={classNames({
                                    'text-neutral-100': getPath() === "users",
                                    'text-neutral-900': getPath() !== "users",
                                })} />
                            </div>
                        </div>
                    </Link>

                    <button onClick={() => isCommissionTabOpened ? setIsCommissionTabOpened(false) : setIsCommissionTabOpened(true)} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "commission",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "commission" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Commission</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "commission" || isCommissionTabOpened,
                                'bg-neutral-300': getPath() !== "commission" || !isCommissionTabOpened,
                            })}>
                                <GiPayMoney className={classNames({
                                    'text-neutral-100': getPath() === "commission" || isCommissionTabOpened,
                                    'text-neutral-900': getPath() !== "commission" && !isCommissionTabOpened,
                                })} />
                            </div>
                        </div>
                    </button>

                    {isCommissionTabOpened && <div className='bg-slate-200 dark:bg-neutral-700 flex flex-col items-end px-8 w-full'>
                        <Link to={`/d/businesses/${getBusinessId()}/commission/details`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "commission" && getSubPath() === "details",
                            })}>Details</div>
                        </Link>
                        <Link to={`/d/businesses/${getBusinessId()}/commission/payments`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "commission" && getSubPath() === "payments",
                            })}>Payments</div>
                        </Link>
                    </div>}

                    <button onClick={() => isSubscriptonTabOpened ? setIsSubscriptonTabOpened(false) : setIsSubscriptonTabOpened(true)} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "subscription",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "subscription" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Subscription</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "subscription" || isSubscriptonTabOpened,
                                'bg-neutral-300': getPath() !== "subscription" && !isSubscriptonTabOpened,
                            })}>
                                <TbMoneybag className={classNames({
                                    'text-neutral-100': getPath() === "subscription" || isSubscriptonTabOpened,
                                    'text-neutral-900': getPath() !== "subscription" && !isSubscriptonTabOpened,
                                })} />
                            </div>
                        </div>
                    </button>

                    {isSubscriptonTabOpened && <div className='bg-slate-200 dark:bg-neutral-700 flex flex-col items-end px-8 w-full'>
                        {/* <Link to={`/d/businesses/${getBusinessId()}/subscription/options`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "subscription" && getSubPath() === "options",
                            })}>Options</div>
                        </Link> */}
                        <Link to={`/d/businesses/${getBusinessId()}/subscription/payments`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "subscription" && getSubPath() === "payments",
                            })}>Payments</div>
                        </Link>
                    </div>}

                    <button onClick={() => isAccountTabOpened ? setIsAccountTabOpened(false) : setIsAccountTabOpened(true)} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "account",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "account" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Manage Account</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "account" || isAccountTabOpened,
                                'bg-neutral-300': getPath() !== "account" && !isAccountTabOpened,
                            })}>
                                <GrUserSettings className={classNames({
                                    'text-neutral-100': getPath() === "account" || isAccountTabOpened,
                                    'text-neutral-900': getPath() !== "account" && !isAccountTabOpened,
                                })} />
                            </div>
                        </div>
                    </button>

                    {isAccountTabOpened && <div className='bg-slate-200 dark:bg-neutral-700 flex flex-col items-end px-8 w-full'>
                        <Link to={`/d/businesses/${getBusinessId()}/account/documents`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "account" && getSubPath() === "documents",
                            })}>Documents</div>
                        </Link>
                        <Link to={`/d/businesses/${getBusinessId()}/account/details`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "account" && getSubPath() === "details",
                            })}>Details</div>
                        </Link>
                    </div>}

                    <div className={classNames({
                        'mt-[100px]':!isMobile,
                        'mt-[50px]':isMobile
                    })}></div>
                    <Link to='/d/businesses' className='flex gap-2 items-center px-8 text-xs underline text-slate-500 dark:text-neutral-400'>
                        <IoReturnUpBackSharp />
                        <div>Select another business</div>
                    </Link>
                </div>
            </div>
        </>
    )
}

function BusinessesLayout({name}:{name:string | null}) {

    return (
        <>
            <BusinessMenu isMobile={false} name={name} />

            <BusinessMobileDrawer>
                <BusinessMenu isMobile={true} name={name}/>
            </BusinessMobileDrawer>
        </>
    )
}

export default BusinessesLayout