import { ApiReturnType } from "@/types/global.types";
import {
  BadRequestError,
  InternalServerError,
  UnauthorizedError,
  ValidationError,
} from "@/utils/errors";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getToken = () => {
  const token = localStorage.getItem("app-tkn");
  return !token ? null : token;
};

export default async function http<T>(
  url: string,
  data?: any,
  token?: string,
  method: "POST" | "GET" = "POST"
) {
  const axios_ = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${token ? token : getToken()}`,
    },
  });
  const resp =
    method === "GET"
      ? await axios_.get<ApiReturnType<T>>(url)
      : await axios_.post<ApiReturnType<T>>(url, data);
  if (resp.data) {
    const code = resp.data.response_code;
    if (code === 200) {
      return resp.data;
    } else if (code === 401) {
      throw new UnauthorizedError(resp.data.message);
    } else if (code === 422) {
      throw new ValidationError(resp.data.message);
    } else {
      throw new BadRequestError(resp.data.message);
    }
  } else {
    throw new InternalServerError();
  }
}

export async function httpRaw<T>(
  url: string,
  data?: any,
  token?: string,
  method: "POST" | "GET" = "POST"
) {
  const axios_ = axios.create({
    headers: {
      Authorization: `Bearer ${token ? token : getToken()}`,
    },
  });
  const resp =
    method === "GET"
      ? await axios_.get<ApiReturnType<T>>(url)
      : await axios_.post<ApiReturnType<T>>(url, data);
  if (resp.data) {
    const code = resp.data.response_code;
    if (code === 200) {
      return resp.data;
    } else if (code === 401) {
      throw new UnauthorizedError(resp.data.message);
    } else if (code === 422) {
      throw new ValidationError(resp.data.message);
    } else {
      throw new BadRequestError(resp.data.message);
    }
  } else {
    throw new InternalServerError();
  }
}
